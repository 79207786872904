import { encryptFields } from './encryptFields';
$.validator.methods.isBusinessEmail = function( value, element ) {
  const freeEmailProviders = ['yahoo.com', 'gmail.com', 'hotmail.com'];
  const emailDomain = value?.trim().split('@')[1];
  return this.optional( element ) || freeEmailProviders.indexOf(emailDomain) < 0;
}
const validationRules = {
  "user[first_name]": {
    required: true,
  },
  "user[last_name]": {
    required: true,
  },
  "user[email]": {
    required: true,
    email: true,
    isBusinessEmail: true
  },
  "user[site]": {
    required: true
  },
  "user[invite_code]": {
    required: true
  },
  "user[site_business_type]": {
    required: true
  },
  "user[blueshift_plan_id]": {
    required: true
  },
  "#register_password": {
    required: true
  },
  "#signUpConfirmPassword": {
    equalTo: "#register_password"
  },
  tnc: {
    required: true
  },
  "user[company_name]": {
    required: true
  }
};

const handleRegister = function () {
  $('.register-form').validate({
    errorElement: 'div',
    errorClass: 'Login-form-error',
    focusInvalid: false,
    ignore: "",
    rules: validationRules,
    messages: {
      "user[email]": {
        isBusinessEmail: "Only business emails are accepted."
      },
      tnc: {
        required: "Please accept our terms of service and privacy policy to continue signing up."
      }
    },

    highlight: function (element) {
      $(element)
        .closest('.Login-form-control')
        .addClass('Login-form-control-error');
    },

    success: function (element) {
      element.closest('.Login-form-control').removeClass('Login-form-control-error');
      element.remove();
    },

    submitHandler: function (form) {
      encryptFields(['user[password]', 'user[password_confirmation]']);
      form.submit(); // form validation success, call ajax form submit
    },

    errorPlacement: function (error, element) {
      if (element.attr('name') === 'tnc') {
        error.insertAfter($('#register_tnc_error'));
      } else {
        error.insertAfter(element.closest('.Login-form-control'));
      }
    },

  });

  $('.register-form input').keypress(function (e) {
    if (e.which == 13) {
      if ($('.register-form').validate().form()) {
        $('.register-form').submit();
      }
      return false;
    }
  });

  jQuery('#back-btn').click(function () {
    window.location.href = '/users/sign_in';
  });

  jQuery('.register-form').show();
};

handleRegister();
