import 'expose-loader?exposes[]=$&exposes[]=jQuery!jquery';
require('./application-styles.scss')
require('jquery-ujs')
import 'bootstrap-sass/assets/javascripts/bootstrap/alert.js'
import 'bootstrap-sass/assets/javascripts/bootstrap/dropdown.js'
import 'bootstrap-sass/assets/javascripts/bootstrap/tooltip.js'
import '../login/validation/jquery.validate.min.js'
import '../login/validation/jsencrypt.min.js'
import '../login/validation/password.js'
import '../login/validation/registration.js'
import '../login/validation/signin.js'
