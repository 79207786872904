import { encryptFields } from './encryptFields';

const handleSignIn = function () {

  $('.login-form').validate({
    errorElement: 'span', //default input error message container
    errorClass: 'help-block', // default input error message class
    focusInvalid: false, // do not focus the last invalid input
    rules: {
      "user[email]": {
        required: true
      },
      "user[password]": {
        required: false
      },
      "user[remember_me]": {
        required: false
      }
    },

    messages: {
      "user[email]": {
        required: "Email is required."
      },
      "user[password]": {
        required: "Password is required."
      }
    },

    invalidHandler: function (event, validator) { //display error alert on form submit
      $('.alert-danger', $('.login-form')).show();
    },

    highlight: function (element) { // hightlight error inputs
      $(element)
        .closest('.form-group').addClass('has-error'); // set error class to the control group
    },

    success: function (label) {
      label.closest('.form-group').removeClass('has-error');
      label.remove();
    },

    // errorPlacement: function (error, element) {
    //   error.insertAfter(element.closest('.input-icon'));
    // },

    submitHandler: function (form) {
      encryptFields(['user[password]']);
      form.submit(); // form validation success, call ajax form submit
    }
  });

  $('.login-form input').keypress(function (e) {
    if (e.which == 13) {
      if ($('.login-form').validate().form()) {
        $('.login-form').submit(); //form validation success, call ajax form submit
      }
      return false;
    }
  });


  if (Object.keys(bsftAppConfig.app_urls || {}).length > 1) {

    let selectedRegion;
    for (let region in bsftAppConfig.app_urls) {
      if (bsftAppConfig.app_urls[region] === window.location.origin) {
        selectedRegion = region;
        break;
      }
    }
    $('.dc-dropdown .dropdown-toggle').tooltip({
      trigger: 'hover'
    });

    function setDataCentre() {
      const email = new window.URLSearchParams(window.location.search).has('email');
      if (email) {
        const path = selectedRegion === 'EU' ? '/assets/images/login/eu.svg' : '/assets/images/login/usa.svg';
        $('.selected-data-centre img').attr({ 'src': path, 'title': selectedRegion }).tooltip({
          trigger: 'hover'
        });
      } else {
        const userId = new window.URLSearchParams(window.location.search).get('data');
        if (userId) {
          $('#loginEmail').val(userId);
          window.history.replaceState(null, null, window.location.pathname);
        }
        const regionChoice = $('.dc-dropdown li:contains(' + `${selectedRegion}` + ')')
        regionChoice.addClass('active');
        regionChoice.siblings().removeClass('active');
        $('.dc-dropdown .dropdown-toggle').html(regionChoice.find('img')[0].outerHTML + '<span class="caret"></span>');
      }
    }

    if ($('.dc-feature').length) {
      setDataCentre();
    }

    if ($('.dc-dropdown').length) {
      $('.dc-dropdown .dropdown-toggle').on('click', function () {
        $('.dc-dropdown .dropdown-toggle').tooltip('hide');
      });

      $('.dc-dropdown li').on('click', function () {

        handleToggle.call(this);
        if (window.location.origin !== bsftAppConfig.app_urls[selectedRegion] && Object.values(bsftAppConfig.app_urls).includes(window.location.origin)) {
          handleNavigation(bsftAppConfig.app_urls[selectedRegion]);
        }

      });

      function handleNavigation(url) {
        if ($('#loginEmail').val().trim() !== '') {
          window.location = url + '?data=' + $('#loginEmail').val();
        } else {
          window.location = url;
        }
      }
      function handleToggle() {
        $('.dc-dropdown .dropdown-toggle').html($(this).children()[0].outerHTML + '<span class="caret"></span>');
        if (selectedRegion !== $(this).find('span').first().attr('dc')) {
          selectedRegion = $(this).find('span').first().attr('dc');
          $(this).addClass('active');
          $(this).siblings().removeClass('active');
        }
      }
    }

  }
};

const setRequestedPage = () => {
  // Store the hash to localStorage on login page, hash would have been retained due to redirect
  const unauthenticatedPages = ['/users/sign_in'];
  if (window.location.hash) {
    let currentPagePath = window.location.pathname;
    let locationHash = window.location.hash;
    
    if (unauthenticatedPages.includes(currentPagePath) && locationHash !== '#/') {
      window.sessionStorage.setItem('requestedPageHash', locationHash);
    }
  }
}

handleSignIn();
setRequestedPage();
