import JSEncrypt from './jsencrypt.min.js'

export function encryptFields(fieldNames) {
  const publicKey = document.querySelector('#pubkey').dataset.value;
  var encrypt = new JSEncrypt();
  encrypt.setPublicKey(publicKey);

  fieldNames.forEach(function (fieldName) {
    var field = document.querySelector(`[name="${fieldName}"]`);
    if (field !== null) {
      var encrypted = encrypt.encrypt(field.value);
      if (encrypted) {
        field.value = encrypted;
      } else {
        console.error("Encryption failed for field: " + fieldName);
      }
    } else {
      console.error("Field not found: " + fieldName);
    }
  });
}
