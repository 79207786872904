import { encryptFields } from './encryptFields';


const handleSetPassword = function () {
  $('.show-password-toggle').on('click', function () {
    //to toggle password show hide on click
    const targetInputId = $(this).data('target');
    const passwordInput = $('#' + targetInputId);

    if (passwordInput.attr('type') === 'password') {
      passwordInput.attr('type', 'text');
      $(this).removeClass('fa-eye').addClass('fa-eye-slash');
    } else {
      passwordInput.attr('type', 'password');
      $(this).removeClass('fa-eye-slash').addClass('fa-eye');
    }
  });

  $('.set-password-form').validate({
    errorElement: 'span', //default input error message container
    errorClass: 'help-block', // default input error message class
    focusInvalid: false, // do not focus the last invalid input
    rules: {
      "user[password]": {
        required: true
      },
      "user[password_confirmation]": {
        equalTo: "#register_password"
      }
    },

    messages: {
      "user[password]": {
        required: "Password is required."
      },
      "user[password_confirmation]": {
        equalTo: "New password and confirm password fields should match"
      }
    },

    invalidHandler: function (event, validator) { //display error alert on form submit
      $('.alert-danger', $('.login-form')).show();
    },

    highlight: function (element) { // hightlight error inputs
      $(element)
        .closest('.form-group').addClass('has-error'); // set error class to the control group
    },

    success: function (label) {
      label.closest('.form-group').removeClass('has-error');
      label.remove();
    },

    errorPlacement: function (error, element) {
      error.insertAfter(element.closest('.input-icon'));
    },

    submitHandler: function (form) {
      encryptFields(['user[password]', 'user[password_confirmation]']);
      form.submit(); // form validation success, call ajax form submit
    }
  });

  $('.set-password-form input').keypress(function (e) {
    if (e.which == 13) {
      if ($('.set-password-form').validate().form()) {
        $('.set-password-form').submit(); //form validation success, call ajax form submit
      }
      return false;
    }
  });
};

const handleForgetPassword = function () {
  $('.forget-form').validate({
    errorElement: 'span', //default input error message container
    errorClass: 'help-block', // default input error message class
    focusInvalid: false, // do not focus the last invalid input
    ignore: "",
    rules: {
      "user[email]": {
        required: true,
        email: true
      }
    },

    messages: {
      email: {
        required: "Email is required."
      }
    },

    invalidHandler: function (event, validator) { //display error alert on form submit

    },

    highlight: function (element) { // hightlight error inputs
      $(element)
        .closest('.form-group').addClass('has-error'); // set error class to the control group
    },

    success: function (label) {
      label.closest('.form-group').removeClass('has-error');
      label.remove();
    },

    errorPlacement: function (error, element) {
      error.insertAfter(element.closest('.input-icon'));
    },

    submitHandler: function (form) {
      form.submit();
    }
  });

  $('.forget-form input').keypress(function (e) {
    if (e.which == 13) {
      if ($('.forget-form').validate().form()) {
        $('.forget-form').submit();
      }
      return false;
    }
  });

  jQuery('.forget-form').show();

  jQuery('#back-btn').click(function () {
    window.location.href = '/users/sign_in';
  });
};

handleSetPassword();
handleForgetPassword();
